<template>
  <div>
    <general-table
      ref="friendsTable"
      :api-url="APIURL"
      :add-type="addType"
      :view-content="false"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="false"
      :block-content="false"
      :type="type"
      :columns="columns"
      :selectable="false"
      :guard="guard"
    />
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'admin/contact_categories',
      addType: 'Add Contact Category',
      addComponentName: 'add-contact-categories',
      editComponent: 'edit-contact-categories',
      viewContent: true,
      type: 'page',
      columns: [
        { key: 'id', sortable: true },
        { key: 'name_en', label: 'English Name' },
        { key: 'name_ar', label: 'Arabic Name' },
        { key: 'actions' },
      ],
    }
  },

}
</script>

<style>

</style>
